<template>
  <div>
    <div class="qr-code-tips-container">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="qr-tips-occupancy text-center">
              <template v-if="company">
                <p class="font-header" style="font-size: 18px;">
                  <template v-if="company.company_section">
                    {{ title.name_km }}{{ company.company_section.name_km }}
                  </template>
                </p>
                <p class="font-en" style="font-size: 18px;">
                  {{ title.name_en }}
                  <template v-if="company.company_section">
                    {{ company.company_section.name_en }}
                  </template>
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row" v-if="company">
        <div class="col-md-12 margin-25">
          <table class="table table-bordered" style="vertical-align: middle">
            <tbody>
            <template>
              <tr class="bg-color-gray">
                <td width="330px;">
                  <p>ឈ្មោះក្រុមហ៊ុន</p>
                </td>
                <td>
                  <p>{{ company.name_km }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td>
                  <p>Company Name</p>
                </td>
                <td>
                  <p>{{ company.name_en }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-white">
                <td>
                  <p>ម្ចាស់ក្រុមហ៊ុន</p>
                </td>
                <td>
                  <template v-if="company.owners && company.owners.length > 0">
                    <template v-for="(owner, key) in company.owners">
                      <div :key="key">
                        <p>{{ owner.name_km || $t('string.na') }}</p>
                        <p>លេខទូរស័ព្ទ : {{ owner.phone || $t('string.na') }}</p>
                      </div>
                    </template>
                  </template>
                  <p v-else> {{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td>
                  <p>Company Owner</p>
                </td>
                <td>
                  <template v-if="company.owners && company.owners.length > 0">
                    <template v-for="(owner, key) in company.owners">
                      <div :key="key">
                        <p>{{ owner.name_en || $t('string.na') }}</p>
                        <p>Phone: {{ owner.phone || $t('string.na') }}</p>
                      </div>
                    </template>
                  </template>
                  <p v-else> {{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-gray">
                <td>
                  <p>នាយកបច្ចេកទេស</p>
                </td>
                <td>
                  <template v-if="company.ctos && company.ctos.length > 0">
                    <template v-for="(cto, key) in company.ctos">
                      <div :key="key">
                        <p>{{ cto.name_km || $t('string.na') }}</p>
                        <p>លេខទូរស័ព្ទ : {{ cto.phone || $t('string.na') }}</p>
                      </div>
                    </template>
                  </template>
                  <p v-else> {{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td>
                  <p>Technical Director</p>
                </td>
                <td>
                  <template v-if="company.ctos && company.ctos.length > 0">
                    <template v-for="(cto, key) in company.ctos">
                      <div :key="key">
                        <p>{{ cto.name_en || $t('string.na') }}</p>
                        <p>Phone: {{ cto.phone || $t('string.na') }}</p>
                      </div>
                    </template>
                  </template>
                  <p v-else> {{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-white">
                <td>
                  <p>នាយកគម្រោង</p>
                </td>
                <td>
                  <template v-if="company.project_managers && company.project_managers.length > 0">
                    <template v-for="(cpm, key) in company.project_managers">
                      <div :key="key">
                        <p>{{ cpm.name_km || $t('string.na') }}</p>
                        <p>លេខទូរស័ព្ទ : {{ cpm.phone || $t('string.na') }}</p>
                      </div>
                    </template>
                  </template>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td>
                  <p>Company Project Manager</p>
                </td>
                <td>
                  <template v-if="company.project_managers && company.project_managers.length > 0">
                    <template v-for="(cpm, key) in company.project_managers">
                      <div :key="key">
                        <p>{{ cpm.name_en || $t('string.na') }}</p>
                        <p>Phone: {{ cpm.phone || $t('string.na') }}</p>
                      </div>
                    </template>
                  </template>
                  <p v-else> {{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-gray">
                <td>
                  <p>លេខចុះបញ្ជី</p>
                </td>
                <td>
                  <p>{{ company.first_registration_number || $t('string.na') }}</p>
                  <p v-if="company.first_registration_date">
                    ចុះ{{ $moment(company.first_registration_date).locale('km').format(date_format_km) }}
                  </p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td>
                  <p>Registration Number</p>
                </td>
                <td>
                  <p>{{ company.first_registration_number || $t('string.na') }}</p>
                  <p v-if="company.first_registration_date">
                    Date: {{ $moment(company.first_registration_date).locale('en').format(date_format_en) }}
                  </p>
                  <p v-else>
                    {{ $t('string.na') }}
                  </p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-white">
                <td><p>អាជីវកម្ម</p></td>
                <td>
                  <template>
                    <p v-if="company.company_section">
                      {{ company.company_section.name_km || $t('string.na') }}
                    </p>
                    <p v-else>{{ $t('string.na') }}</p>
                  </template>
                  <template>
                    <p v-if="company.company_type">ប្រភេទទី{{ company.company_type.name_km || $t('string.na') }}</p>
                    <p v-else>{{ $t('string.na') }}</p>
                  </template>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td><p>Type of Business</p></td>
                <td>
                  <template>
                    <p v-if="company.company_section">{{ company.company_section.name_en || $t('string.na') }}</p>
                    <p v-else>{{ $t('string.na') }}</p>
                  </template>
                  <template>
                    <p v-if="company.company_type">Class {{ company.company_type.name_en || $t('string.na') }}</p>
                    <p v-else>{{ $t('string.na') }}</p>
                  </template>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-gray">
                <td><p>សញ្ជាតិ</p></td>
                <td>
                  <p v-if="company.nationality">{{ company.nationality.name_km || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td><p>Nationality</p></td>
                <td>
                  <p v-if="company.nationality">{{ company.nationality.name_en || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-white">
                <td>
                  <p>លេខប្រកាសរបស់ក្រសួង​​​​ដែនដី នគរូបនីយកម្ម និងសំណង់</p>
                </td>
                <td>
                  <template v-if="company.ministry_registrations && company.ministry_registrations.length > 0">
                    <template v-for="(cmr, key) in company.ministry_registrations">
                      <p :key="key">
                        លេខ: {{ cmr.number_km || $t('string.na') }}
                        មានសុពលភាព :
                        <template v-if="cmr.issued_date || cmr.effective_date">
                          <template v-if="cmr.issued_date">
                            ពី{{ $moment(cmr.issued_date).locale('km').format(date_format_km) }}
                          </template>
                          <template v-else>ពី {{ $t('string.na') }}</template>
                          <template v-if="cmr.effective_date">
                            ដល់{{ $moment(cmr.effective_date).locale('km').format(date_format_km) }}
                          </template>
                          <template v-else>ដល់ {{ $t('string.na') }}</template>
                        </template>
                        <template v-else>{{ $t('string.na') }}</template>
                      </p>
                    </template>
                  </template>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td>
                  <p>Ministry's Declaration Number</p>
                </td>
                <td>
                  <template v-if="company.ministry_registrations && company.ministry_registrations.length > 0">
                    <template v-for="(cmr, key) in company.ministry_registrations">
                      <p :key="key">
                        Number: {{ cmr.number || $t('string.na') }}
                        Validity :
                        <template v-if="cmr.issued_date || cmr.effective_date">
                          <template v-if="cmr.issued_date">
                            {{ $moment(cmr.issued_date).locale('en').format(date_format_en) }}
                          </template>
                          <template v-else>{{ $t('string.na') }}</template>
                          <template v-if="cmr.effective_date">
                            To {{ $moment(cmr.effective_date).locale('en').format(date_format_en) }}
                          </template>
                          <template v-else>To {{ $t('string.na') }}</template>
                        </template>
                        <template v-else>{{ $t('string.na') }}</template>
                      </p>
                    </template>
                  </template>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-gray">
                <td><p>អាសយដ្ឋាន</p></td>
                <td>
                  <p>{{ company.full_address_km || $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td><p>Address</p></td>
                <td>
                  <p>{{ company.full_address_en || $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyDetail',
  props: {
    title: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Object
    }
  },
  data () {
    return {
      company: null,
      date_format_km: 'ថ្ងៃទីDD ខែMMM ឆ្នាំYYYY',
      date_format_en: 'DD MMMM YYYY'
    }
  },
  methods: {
    getCompany () {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/company/show', {
        qr_code: this.$route.params.qr_code
      }).then(({ data }) => {
        this.company = data.data
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    this.getCompany()
  }
}
</script>

<style scoped>
@import "./../../../assets/css/customeTable.css";

.label-title {
  font-size: 38px;
}

.qr-code-tips-container {
  background-color: #3c8dbc;
  padding: 5px;
  width: 100%;
}

.qr-tips-occupancy {
  width: 100%;
  color: #ffffff;
  padding-top: 10px;
  font-size: 22px;
}
</style>
