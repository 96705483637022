<template>
  <company-detail :title="title"/>
</template>

<script>
import CompanyDetail from '../includes/CompanyDetail'

export default {
  name: 'License',
  metaInfo () {
    return {
      title: 'Certificate Of License',
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: { CompanyDetail },
  data () {
    return {
      title: {
        name_en: 'Certificate Of License',
        name_km: 'វិញ្ញាបនបត្របញ្ជាក់ការអនុញ្ញាតឱ្យប្រកបអាជីវកម្ម'
      }
    }
  }
}
</script>

<style scoped>

</style>
